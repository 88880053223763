import Vue from "vue";

const fetchOrdersSummary = async (orders) => {
    if (orders && orders.length > 0) {
        const orderIds = orders.map((order) => order.id);
        console.log("fetching order summary info: " + orderIds);
        return Vue.axios.get("/salesOrder/order-status", {
            params: { orderIds: orderIds.join(",") },
        })
            .then((resp) => {
                const ordersSummary = resp.data;
                if (ordersSummary && ordersSummary.length > 0) {
                    return ordersSummary;
                }
                else {
                    return []
                }
            });
    }
}

/**
 * Search for outstanding orders for a product; summarize its order status and outstanding order quantity
 * @param {*} productId 
 * @returns 
 */
const fetchProductOrdersSummary = async (productId) => {
    const params = {
        page: 0,
        size: 1000,
        sort: "id,desc",
        statuses: ["OPEN", "PARTIALLY_FILLED" ].join(','),
        productId
      };

      let summary = null
      await Vue.axios.get("/salesOrder", { params }).then((resp) => {
        const salesOrders = resp.data.content;

        if (salesOrders && salesOrders.length > 0) {
          summary = salesOrders.reduce( (sum, o) => {
            const customers = sum.customers;
            if(!customers.includes(o.customerName)){
              customers.push({customerName: o.customerName, customerId: o.customerId});
            }

            sum.orders.push(o);
            return sum;
          }, { totalOrderQuantity: 0, totalFilledQuantity: 0, customers: [], orders: [] });

          fetchOrdersSummary(salesOrders).then((ordersSummary) => {
            ordersSummary.forEach((s) => {
              summary.totalOrderQuantity += s.weight;
              summary.totalFilledQuantity += s.filledWeight;
            });
          });
        }

      });

      return summary;
}

const fetchOrderPackingList = (orderId) =>
    Vue.axios.get("/packingList/by-sales-order", {
        params: { saleOrderId: orderId },
    })

const markSalesOrderAsCompleted = (orderId) => Vue.axios.put("/salesOrder/mark-completed/" + orderId)

const markSalesOrderAsIncomplete = (orderId) => Vue.axios.put("/salesOrder/mark-incomplete/" + orderId)


const deletePackingList = (packingListId) => Vue.axios.delete("/packingList/" + packingListId)

export { fetchOrdersSummary, fetchProductOrdersSummary, fetchOrderPackingList, markSalesOrderAsCompleted, markSalesOrderAsIncomplete, deletePackingList }